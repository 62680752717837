<template>
  <div>
    <!-- Table Container Card -->
    <user-password-edit :user-info=userInfo />
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>
          過濾器
        </h5>
      </b-card-header>
      <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
        <label>狀態</label>
        <v-select
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        v-model="statusFilter"
        :options="statusOptions"
        class="w-100"
        :reduce="val => val.value"
      />
        </b-col>

        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
        <label>已刪除</label>
        <v-select
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        v-model="deleteFilter"
        :options="deleteOptions"
        class="w-100"
        :reduce="val => val.value"
      />
        </b-col>

        </b-row>
        </b-card-body>
      </b-card>

    <b-card no-body class="mb-0">

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>Show</label>
            <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions"
              :clearable="false" class="per-page-selector d-inline-block mx-50" />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="搜尋..." />
              <b-button variant="primary" v-if="ability.can('create', 'users')" @click="isAddNewUserSidebarActive = true"
                :to="{ name: 'users-create' }">
                <span class="text-nowrap">新增員工</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table ref="refUserListTable" class="position-relative" :items="fetchUsers" responsive :fields="tableColumns"
        primary-key="id" :sort-by.sync="sortBy" show-empty empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc">


        <!-- Column: roles  -->
        <template #cell(role)="data">
          <div v-if="data.item.role">
            <span v-for="item in data.item.role" :key="item.id">{{ item.name }}</span>
          </div>
        </template>
        <!--Column: Position -->
        <template #cell(position_id)="data">
          <div v-if="data.item.position">{{ data.item.position.position }}</div>
        </template>

        <!--Column: Team Lewader -->

        <template #cell(is_teamleader)="data">
          <b-badge pill v-if="data.item.is_teamleader" :variant="`light-primary`" class="text-capitalize">
            Team Leader
          </b-badge>
        </template>
        <!-- Column: Team -->
        <template #cell(team_id)="data">
          <div v-for="team in data.item.teams" :key="team.id" class="text-capitalize">
            {{ team.team_name }}
          </div>
        </template>

        <template #cell(is_active)="data">
          <feather-icon v-if="data.item.is_active" icon="CheckIcon" size="18" class="mr-50 text-success" />
        </template>
        <template #cell(end_current_employment_cycle)="data">
          <span v-if="data.item.day_of_employment" :class="`${dateDifferent(new Date(), getNextCycleEndDate(data.item.current_employment_cycle))<60? 'text-danger':''}`">{{getNextCycleEndDate(data.item.current_employment_cycle)}}</span>
        </template>
        <template #cell(day_left)="data">
          <span v-if="data.item.day_of_employment" :class="`${dateDifferent(new Date(), getNextCycleEndDate(data.item.current_employment_cycle))<60? 'text-danger':''}`">{{dateDifferent(new Date(), getNextCycleEndDate(data.item.current_employment_cycle))}}</span>
        </template>
        <template #cell(annual_leave_balance)="data">
          <span v-if="data.item.day_of_employment" :class="`${dateDifferent(new Date(), getNextCycleEndDate(data.item.current_employment_cycle))<60 && data.item.annual_leave - data.item.annual_leave_used > 0? 'text-danger':'text-success'}`">{{data.item.annual_leave - data.item.annual_leave_used}}</span>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">

            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
            </template>
     <!--        <b-dropdown-item :to="{ name: 'users-view', params: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">詳情</span>
            </b-dropdown-item> -->

            <b-dropdown-item v-if="ability.can('update', 'users') && data.item.deleted_at == null"
              :to="{ name: 'users-edit', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">修改</span>
            </b-dropdown-item>
            <b-dropdown-item v-if="ability.can('update', 'users') && data.item.deleted_at == null" @click="triggerResetPasswordModal(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">重設密碼</span>
            </b-dropdown-item>
            <b-dropdown-item v-if="ability.can('delete', 'users') && data.item.deleted_at == null"
            @click="confirmDelete(data.item.id)">
            <feather-icon icon="TrashIcon" />
            <span class="align-middle ml-50">刪除</span>
          </b-dropdown-item>
          <b-dropdown-item v-if="ability.can('delete', 'users') && data.item.deleted_at"
                @click="confirmRestore(data.item.id)">
                <feather-icon icon="RotateCcwIcon" />
                <span class="align-middle ml-50">恢復</span>
              </b-dropdown-item>



          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">

            <b-pagination v-model="currentPage" :total-rows="totalUsers" :per-page="perPage" first-number last-number
              class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BCardHeader,
    BCardBody,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import useUsersList from './useUsersList'
import userStoreModule from '../userStoreModule'
import UserListAddNew from './UserListAddNew.vue'
import UserPasswordEdit from "./UserPasswordEdit.vue"
import {getNextCycleEndDate, dateDifferent} from "@/libs/helper"

export default {
  components: {
    UserListAddNew,
    UserPasswordEdit,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BCardHeader,
    BCardBody,
    vSelect,
  },
  methods: {
    confirmDelete(id) {
      this.$swal({
        title: "確認刪除?",
        text: "",
        showCancelButton: true,
        confirmButtonText: "刪除",
        cancelButtonText: "取消",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("app-user/deleteUser", { id: id })
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.refetchData();
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    confirmRestore(id) {
      this.$swal({
        title: "確認刪恢復?",
        showCancelButton: true,
        confirmButtonText: "恢復",
        cancelButtonText: '取消',
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch(`app-user/restoreUser`, id)
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.refetchData();
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    triggerResetPasswordModal(item) {
      console.log('triggerResetPasswordModal', item)
      this.userInfo.id = item.id
      this.userInfo.name = item.name
      this.userInfo.password = ''
      this.userInfo.password_confirmation = ''
      this.$bvModal.show('modal-reset-user-password')
    }
  },
  data() {
    return {
      userInfo: { 'id': 0, 'password': '', 'password_confirmation': '' },
      getNextCycleEndDate,
      dateDifferent
    };
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const isAddNewUserSidebarActive = ref(false)


    const statusOptions = [
      { label: '啟用', value: 1 },
      { label: '禁用', value: 0},
      { label: '全部', value: null },
    ]

    const deleteOptions = ref([
        {
          label: "已刪除",
          value: 1,
        },
      ]);

    const {
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      ability,
      deleteFilter,
    } = useUsersList()

    return {

      // Sidebar
      isAddNewUserSidebarActive,

      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      ability,

      // Filter
      avatarText,   
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      deleteFilter,
      deleteOptions,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
